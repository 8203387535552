import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from './backend';

export const configureI18n = async (messages?: Record<string, Record<string, object>>, locales?: Record<string, string>) => {
    await i18n
        .use(backend)
        .use(initReactI18next)
        .init({
            backend: {
                loadPath: (lang: string) => locales?.[lang] ? `/${locales[lang]}` : undefined,
            },
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false,
            },
            load: 'languageOnly',
        });

    if (messages) {
        Object.keys(messages)
            .forEach((lang) => {
                Object.keys(messages[lang])
                    .forEach((ns) => {
                        i18n.addResourceBundle(lang, ns, messages[lang][ns]);
                    });
            });
    }

    if (import.meta?.hot) {
        import.meta.hot.on('locales-update', (data: Record<string, Record<string, object>>) => {
            Object.keys(data)
                .forEach((lang) => {
                    Object.keys(data[lang])
                        .forEach((ns) => {
                            i18n.addResourceBundle(lang, ns, data[lang][ns]);
                        });
                });
        });
    }
};
