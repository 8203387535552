
import { useSelector } from 'react-redux';
import { ApplicationContext } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';
import { AppState } from 'store/store';

const getApplicationContext = (app: AppState): Nullable<ApplicationContext> =>
    app.applicationContext.context;

type UseApplicationContextResponse = {
    connectedIcId: Nullable<string>;
    preferredLanguage: Nullable<string>;
    hasOnlyB2b2cAccess: Nullable<boolean>;
    canSearchPersonsAndCompanies: Nullable<boolean>;
    isSgGroupCurrentUser: Nullable<boolean>;
    isInsightSubscriptionsVisible: Nullable<boolean>;
    isSharinboxVisible: Nullable<boolean>;
    is3SKeyVisible: Nullable<boolean>;
};

export const useApplicationContext = (): UseApplicationContextResponse => {
    const applicationContext = useSelector(getApplicationContext);

    return {
        connectedIcId: applicationContext?.user?.IcId,
        preferredLanguage: applicationContext?.preferredLanguage,
        hasOnlyB2b2cAccess: applicationContext?.hasOnlyB2b2cAccess,
        canSearchPersonsAndCompanies: applicationContext?.canSearchPersonsAndCompanies,
        isSgGroupCurrentUser: applicationContext?.isSgGroupCurrentUser,
        isInsightSubscriptionsVisible: applicationContext?.isSubscriptionsVisible,
        isSharinboxVisible: applicationContext?.isSharinboxVisible,
        is3SKeyVisible: applicationContext?.is3SKeyVisible,
    };
};
