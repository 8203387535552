import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { SgmServiceInfoLink } from './InfoLink';
import { TooltipButton } from 'components/common/tooltip/Tooltip';
import { BookmarkDropdownItem } from '../bookmark/BookmarkButtons';
import { BookmarkOrigin } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type DropdownDetailsProps = {
    serviceKey: string;
    onToggle?: (event: React.MouseEvent<any, MouseEvent>) => void;
    bookmarkOrigin: Nullable<BookmarkOrigin>;
};

export const DropdownDetails: React.FunctionComponent<DropdownDetailsProps> = ({
    serviceKey,
    onToggle,
    bookmarkOrigin,
}) => {
    const { t: translate } = useTranslation('service-list');
    const [areDetailsOpen, setAreDetailsOpen] = useState(false);

    const toggleDetails = (event: React.MouseEvent<any, MouseEvent>) => {
        if (onToggle) {
            onToggle(event);
        }
        setAreDetailsOpen(!areDetailsOpen);
    };

    return (
        <Dropdown
            isOpen={areDetailsOpen}
            toggle={toggleDetails}
            direction="start"
            className="d-flex justify-content-center"
        >
            <DetailsButton />
            <DropdownMenu
                style={{
                    width: '170px',
                }}
            >
                <BookmarkDropdownItem
                    referenceId={serviceKey}
                    origin={bookmarkOrigin}
                />
                <DropdownItem className="p-0">
                    <SgmServiceInfoLink
                        serviceKey={serviceKey}
                        className="btn btn-block btn-lg d-flex justify-content-between ps-3"
                        iconClassName="icon align-self-center text-secondary"
                    >
                        {translate('service-list:viewDetails')}
                    </SgmServiceInfoLink>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

const DetailsButton = () => (
    <DropdownToggle
        className="btn btn-lg btn-icon btn-flat-secondary"
        tag={TooltipButton}
        tooltipId="more"
    >
        <i className="icon">more_vert</i>
    </DropdownToggle>
);
